import { object, string } from "prop-types";
import { Suspense, lazy, memo } from "react";

import styled from "styled-components/macro";
import { MAX_MODAL_PX_HEIGHT, MAX_MODAL_VH } from "theme/interpolations/height";

import flocknoteStateHooks from "Flocknote/stateHooks";

import FnrCard from "UI/FnrCard/FnrCard";
import FnrCloseButton from "UI/FnrCloseButton";
import FnrModal from "UI/FnrModal";
import RunningUno from "UI/RunningUno";
import { scrollbarInvisibleThin } from "UI/scrollbarStyles";

import configsByContentKey from "./configsByContentKey";
import {
  CREATE_ADDON_GIFT,
  CREATE_GROUP,
  CTA_EDITOR,
  DONOR_SUMMARY_REPORTS,
  EXTENDED_FIELDS,
  FAMILY_RELATIONSHIP_PICKER,
  FNC_SMART_GROUP_PROMO,
  FNC_UPGRADE,
  GIFT_REFUND,
  HOUSEHOLD_DB_VIDEO,
  MANAGE_PROXY_CONTACTS,
  SACRAMENT_TRACKING,
  STAX_KYC,
  STAX_STATUS,
  TWO_FA_DISENROLLMENT,
  TWO_FA_ENROLLMENT,
} from "./contentKeys";

const ModalContents = memo(({ contentKey, contentProps }) => {
  let Component;
  // Add additional case statements here for the components you'd like to add.
  switch (contentKey) {
    case EXTENDED_FIELDS:
      Component = lazy(() => import("ExtendedFields/ExtendedFields"));
      break;
    case CREATE_ADDON_GIFT:
      Component = lazy(() => import("Giving/CreateAddOnGift"));
      break;
    case CTA_EDITOR:
      Component = lazy(() => import("Giving/FundItSettings/CtaEditor"));
      break;
    case STAX_KYC:
      Component = lazy(() => import("Giving/StaxKYC"));
      break;
    case STAX_STATUS:
      Component = lazy(() => import("Giving/StaxStatus"));
      break;
    case GIFT_REFUND:
      Component = lazy(() =>
        import("Giving/GiftRefundOverlay/GiftRefundOverlay")
      );
      break;
    case FNC_UPGRADE:
      Component = lazy(() => import("Giving/FNCUpgradeOverlay"));
      break;
    case HOUSEHOLD_DB_VIDEO:
      Component = lazy(() =>
        import("Dashboard/DashboardMainSections/HouseholdDBSplash/VideoModal")
      );
      break;
    case SACRAMENT_TRACKING:
      Component = lazy(() => import("SacramentTracking/Overlay"));
      break;
    case TWO_FA_ENROLLMENT:
      Component = lazy(() => import("UserProfile/TwoFAEnrollment"));
      break;
    case TWO_FA_DISENROLLMENT:
      Component = lazy(() => import("UserProfile/TwoFA/TwoFADisenrollment"));
      break;
    case MANAGE_PROXY_CONTACTS:
      Component = lazy(() => import("ManageProxyContacts/ManageProxyContacts"));
      break;
    case FAMILY_RELATIONSHIP_PICKER:
      Component = lazy(() =>
        import("FamilyRelationshipPicker/FamilyRelationshipPicker")
      );
      break;
    case DONOR_SUMMARY_REPORTS:
      Component = lazy(() =>
        import("Giving/TaxSummaryEOY/DonorSummaryReports")
      );
      break;
    case CREATE_GROUP:
      Component = lazy(() =>
        import("CrossFeatureComponents/CreateGroup/CreateGroup")
      );
      break;
    case FNC_SMART_GROUP_PROMO:
      Component = lazy(() => import("Group/FNCSmartGroupPromo"));
      break;
    default:
      Component = () => null;
  }
  return <Component {...contentProps} isModal />;
});

ModalContents.propTypes = { contentKey: string, contentProps: object };

const overflow = ({ $flexHeight }) => {
  if ($flexHeight) return `overflow-y: auto;`;
  return `overflow-y: hidden;`;
};

const MAX_MODAL_VW = "90vw";
const MAX_MODAL_PX_WIDTH = "800px";

const ContentContainer = styled(FnrCard)`
  ${({
    theme: {
      palette: { grey },
    },
    $flexHeight,
    $flexWidth,
    $maxWidth = MAX_MODAL_PX_WIDTH,
    $maxHeight = `${MAX_MODAL_PX_HEIGHT}px`,
    $minHeight,
    $legacy,
  }) => `
  ${
    $flexWidth
      ? `max-width: ${MAX_MODAL_VW};`
      : `width: min(${$maxWidth}, ${MAX_MODAL_VW});`
  }
  ${
    $flexHeight
      ? `max-height: ${MAX_MODAL_VH}vh;`
      : `height: ${`min(${$maxHeight}, ${MAX_MODAL_VH}vh)`};`
  }
  ${$legacy ? "margin: 8px;" : ""}
  ${$minHeight ? `min-height: ${$minHeight};` : ""}
  background-color: ${grey};`}
  position: relative;
  ${overflow}
  ${scrollbarInvisibleThin}
  scrollbar-width: none;
`;

const Modal = () => {
  const [{ isOpen, contentKey, contentProps }, { closeModal }] =
    flocknoteStateHooks.useModal();

  const handleClose = () => {
    // pass in onClose as a special contentProp to fire whatever you need when the modal closes:
    // openModal('someKey',' {onClose: () => {}})
    if (contentProps?.onClose) contentProps.onClose();
    closeModal();
  };

  const handleBackgroundClick = () => {
    if (configsByContentKey.closeOnBackgroundClick[contentKey]) closeModal();
  };

  const flexHeight = configsByContentKey.flexHeight[contentKey];
  const flexWidth = configsByContentKey.flexWidth[contentKey];
  const maxWidth = configsByContentKey.maxWidth[contentKey];
  const maxHeight = configsByContentKey.maxHeight[contentKey];
  const minHeight = configsByContentKey.minHeight[contentKey];
  const legacy = configsByContentKey.legacy[contentKey];
  const closeButtonPosition =
    configsByContentKey.closeButtonPosition[contentKey];

  return (
    <FnrModal
      show={isOpen}
      data-cy="Global_Modal"
      onBackgroundClick={handleBackgroundClick}
    >
      {closeButtonPosition === "hide" ? null : (
        <FnrCloseButton
          onClick={handleClose}
          aria-label="close-modal"
          legacy={legacy}
          position={closeButtonPosition}
        />
      )}
      <ContentContainer
        data-cy="Modal_Container"
        $flexHeight={flexHeight}
        $flexWidth={flexWidth}
        $maxWidth={maxWidth}
        $maxHeight={maxHeight}
        $minHeight={minHeight}
        variant="modal"
        $legacy={legacy}
      >
        <Suspense fallback={<RunningUno />}>
          <ModalContents contentProps={contentProps} contentKey={contentKey} />
        </Suspense>
      </ContentContainer>
    </FnrModal>
  );
};

export { MAX_MODAL_VW, MAX_MODAL_PX_WIDTH };
export default Modal;